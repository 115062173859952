import React from 'react'
import { Router } from '@reach/router'

import Seo from '../components/SEO/Seo'
import CheckoutWrapper from '../components/Checkout/CheckoutWrapper'
import Success from '../components/Checkout/Success'

const CheckoutPage = ( { location } ) => {

	return (
		<>
			<Seo title="Checkout" pathname={ location.pathname } />
			<Router basepath="/checkout">
				<CheckoutWrapper default />
				<Success path="/success" />
			</Router>
		</>
	)
}

export default CheckoutPage