import React from 'react'

export default function PaymentMethod( props ) {

	const { setPaymentMethod } = props

	return (
		<div className="payment-method">
			<div className="form__input-container">
				<label className="form__checkbox-label" htmlFor="paymentPaypal">
					<input
						type="radio"
						className="form__checkbox-input"
						name="paymentMethod"
						id="paymentPaypal"
						value="paypal"
						onChange={ (e) => setPaymentMethod( e.target.value ) } />
					<div className="form__checkbox-checkmark"></div>
					<span className="form__checkbox-text">
						Paypal
					</span>
				</label>
			</div>

			<div className="form__input-container">
				<label className="form__checkbox-label" htmlFor="card">
					<input
						type="radio"
						className="form__checkbox-input"
						name="paymentMethod"
						id="card"
						value="card"
						onChange={ (e) => setPaymentMethod( e.target.value ) } />

					<div className="form__checkbox-checkmark"></div>
					<span className="form__checkbox-text" >
						Card Payment
					</span>
				</label>
			</div>
		</div>
	)
}