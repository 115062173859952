import React, { useState, useEffect, useRef } from 'react'

export default function BillingDetails( props ) {

	const { deliveryDetails, billingDetails, setBillingDetails, setSubscriberFirstName, setSubscriberLastName } = props;

	const form = useRef();

	const [ checked, toggleChecked ] = useState( true )
	const [ firstName, setFirstName ] = useState( '' )
	const [ lastName, setLastName ] = useState( '' )
	const [ addressLine1, setAddressLine1 ] = useState( '' )
	const [ addressLine2, setAddressLine2 ] = useState( '' )
	const [ city, setCity ] = useState( '' )
	const [ postcode, setPostcode ] = useState( '' )
	const [ county, setCounty ] = useState( '' )
	const [ country, setCountry ] = useState( '' )

	useEffect( () => {
		if ( !deliveryDetails ) {
			return;
		}

		if ( checked ) {
			setBillingDetails({
				billingName: deliveryDetails.shippingName,
				billingFirstName: deliveryDetails.shippingFirstName,
				billingLastName: deliveryDetails.shippingLastName,
				billingAddressLine1: deliveryDetails.shippingAddressLine1,
				billingAddressLine2: deliveryDetails.shippingAddressLine2, 
				billingCity: deliveryDetails.shippingCity,
				billingPostcode: deliveryDetails.shippingPostcode,
				billingCounty: deliveryDetails.shippingCounty,
				billingCountry: deliveryDetails.shippingCountry,
			})

			setSubscriberFirstName( deliveryDetails.shippingFirstName )
			setSubscriberLastName( deliveryDetails.shippingLastName )
		}
	}, [ deliveryDetails, setBillingDetails, checked, setSubscriberFirstName, setSubscriberLastName ] )

	useEffect(()=>{
		setBillingDetails({
			billingName: firstName + " " + lastName,
			billingFirstName: firstName,
			billingLastName: lastName,
			billingAddressLine1: addressLine1,
			billingAddressLine2: addressLine2, 
			billingCity: city,
			billingPostcode: postcode,
			billingCounty: county,
			billingCountry: country,
		})

		setSubscriberFirstName( firstName )
		setSubscriberLastName( lastName )
	}, [ firstName, lastName, addressLine1, addressLine2, city, postcode, county, country, setBillingDetails, setSubscriberFirstName, setSubscriberLastName ] )


	const setDetailsToDelivery = () => {
		const isChecked = !checked; // due to state update delay
		toggleChecked( !checked );

		if ( isChecked ) {
			setBillingDetails({
				billingName: deliveryDetails.shippingName,
				billingFirstName: deliveryDetails.shippingFirstName,
				billingLastName: deliveryDetails.shippingLastName,
				billingAddressLine1: deliveryDetails.shippingAddressLine1,
				billingAddressLine2: deliveryDetails.shippingAddressLine2, 
				billingCity: deliveryDetails.shippingCity,
				billingPostcode: deliveryDetails.shippingPostcode,
				billingCounty: deliveryDetails.shippingCounty,
				billingCountry: deliveryDetails.shippingCountry,
			})
			

			setFirstName( deliveryDetails.shippingFirstName );
			setLastName( deliveryDetails.shippingLastName );
			setAddressLine1( deliveryDetails.shippingAddressLine1 );
			setAddressLine2( deliveryDetails.shippingAddressLine2 );
			setCity( deliveryDetails.shippingCity );
			setPostcode( deliveryDetails.shippingPostcode );
			setCounty( deliveryDetails.shippingCounty );
			setCountry( deliveryDetails.shippingCountry );
		}
	}

	const handleFormSubmit = ( event ) => {
		event.preventDefault()
		form.current.reportValidity()
	}

	return (
		<>
			{ billingDetails && !checked &&
				<form className="form" ref={ form }>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="firstName">First Name</label>
						<input
							className="form__input form__input--pink"
							type="text" name="firstName" id="firstName" value={ firstName } placeholder="First name"
							onChange={ e => { setFirstName( e.target.value ) } }
							onBlur={ e => { handleFormSubmit( e ) }}
							required />
					</div>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="lastName">Last Name</label>
						<input
							className="form__input form__input--yellow"
							type="text" name="lastName" id="lastName" value={ lastName } placeholder="Last name"
							onChange={ e => { setLastName( e.target.value ) } }
							onBlur={ e => { handleFormSubmit( e ) }}
							required />
					</div>

					<div className="form__input-container">
						<label className="form__label"  htmlFor="addressLine1">House No/Name</label>
						<input
							className="form__input form__input--green"
							type="text" name="addressLine1" id="addressLine1" value={ addressLine1 } placeholder="House number/name"
							onChange={ e => { setAddressLine1( e.target.value ) } }
							onBlur={ e => { handleFormSubmit( e ) }}
							required />
					</div>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="addressLine2">Address 2</label>
						<input
							className="form__input form__input--blue"
							type="text" name="addressLine2" id="addressLine2" value={ addressLine2 } placeholder="Address Line 2"
							onChange={ e => { setAddressLine2( e.target.value ) } }/>
					</div>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="city">City</label>
						<input
							className="form__input form__input--pink"
							type="text" name="city" id="city" value={ city } placeholder="City"
							onChange={ e => { setCity( e.target.value ) } }
							onBlur={ e => { handleFormSubmit( e ) }}
							required />
					</div>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="postcode">Postcode</label>
						<input
							className="form__input form__input--yellow"
							type="text" name="postcode" id="postcode" value={ postcode } placeholder="Postcode"
							onChange={ e => { setPostcode( e.target.value ) } }
							onBlur={ e => { handleFormSubmit( e ) }}
							required />
					</div>

					<div className="form__input-container">
						<label className="form__label"  htmlFor="county">County</label>
						<input
							className="form__input form__input--green"
							type="text" name="county" id="county" value={ county } placeholder="County"
							onChange={ e => { setCounty( e.target.value ) } }
							onBlur={ e => { handleFormSubmit( e ) }}
							required/>
					</div>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="country">Country</label>
						<input
							className="form__input form__input--blue"
							type="text" name="country" id="country" value={ country } placeholder="Country"
							onChange={ e => { setCountry( e.target.value ) } }
							onBlur={ e => { handleFormSubmit( e ) }}
							required/>
					</div>
				</form>
			}

			<div className={`form__input-container ${ !checked ? 'form__input-container--margin-top' : '' }`}>
					<label className="form__checkbox-label" htmlFor="billingAddress">
					<input
						className="form__checkbox-input"
						type="checkbox"
						id="billingAddress"
						defaultChecked={ checked }
						onChange={ () => { setDetailsToDelivery() } }/>
						<div className="form__checkbox-checkmark"></div>
					<span className="form__checkbox-text">Use delivery address as billing address</span>
				</label>
			</div>
		</>
	)
}