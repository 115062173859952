import React, { useContext } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { CartContext } from '../../context/CartContext';
import { formatPrice } from '../../helpers/currency';

export default function CheckoutProductList() {

	const { cart, hasOfferApplied } = useContext( CartContext );

	return (
		<>
			{ cart.map( ( product, index ) => {
				const productHasOffer = hasOfferApplied.some( offer => offer.name === product.category.name ) && product.offerPrice

				return ( 
					<Link to={`/${ product.slug }/`} className="checkout-product" key={ index }>

						<div className="checkout-product__image-container">
							<GatsbyImage
								image={product.images[0].imageFile.childImageSharp.gatsbyImageData}
								className="checkout-product__image"
								objectFit="contain"
								loading="lazy"
								alt={ product.name }
							/>
						</div>

						<div className="checkout-product__info">
							<div className="checkout-product__name">{ product.name }</div>

							<div className="checkout-product__quantity">
								Quantity: { product.quantity }
							</div>

							<div className="checkout-product__price-container" >
								<div className={`checkout-product__price ${ productHasOffer ? "offer" : '' }` } >{ formatPrice( product.price ) }</div>
								{ productHasOffer &&
									<div className="checkout-product__price">{ formatPrice( product.offerPrice ) }</div>
								}
							</div>
						</div>
					</Link>
			) } ) }
		</>
	)
}