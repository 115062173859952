import React from 'react'
import { Link } from 'gatsby'

import Heading from '../Heading/Heading'

export default function Success() {

	return (
		<>
			<div className="checkout checkout--success">
				<Heading heading="Your order has been processed!" />
				<div className="wrapper">
					<div className="checkout__success-container">
						<p className="checkout__success-text">You will receive a confirmation email shortly.</p>
						<p className="checkout__success-text">Please direct any questions you have to Arcipluvia by using the <Link to="/#contact">contact form</Link>.</p>
						<p className="checkout__success-text">Thanks for shopping with us online!</p>
					</div>
				</div>
			</div>
		</>
	)
}
