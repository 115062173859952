import React, { useState, useContext } from 'react';

import { CartContext } from '../../context/CartContext';

import CheckoutProductList from './CheckoutProductList'
import Heading from '../Heading/Heading'
import Checkout from './Checkout';

import { formatPrice } from '../../helpers/currency';
import { cartTotal, cartSubTotal } from '../../helpers/cart';

import Loader from '../../images/svgs/loader.svg'

const CheckoutWrapper = () => {

	const { cart, postage, offers } = useContext( CartContext )
	const [ processing, setProcessing ] = useState()

	return (
		<>
			{ cart && cart.length > 0 ? (
				<div className="wrapper">
					<div className="checkout">
						<Checkout setProcessing={ setProcessing } />
						<div className="checkout__price-container u-hide-phablet">
							<h2 className="checkout__header">Your Art Cart</h2>
							<CheckoutProductList />
							<div className="checkout__totals-container">
								<div className="checkout__totals">Subtotal <span className="checkout__totals-price">{ formatPrice( cartSubTotal( cart, offers ) ) }</span></div>
								<div className="checkout__totals">Delivery <span className="checkout__totals-price">{ postage.price ? formatPrice( postage.price ) : 'Free' }</span></div>
								<div className="checkout__totals checkout__totals--total">Grand Total <span className="checkout__totals-price">{ formatPrice( cartTotal( cart, postage, offers ) ) }</span></div>
							</div>
						</div>
					</div>

					{ processing &&
						<div className="checkout__loading-container">
							<div className="checkout__loading-text-container">
								<Loader className="loader" />
								<h3 className="checkout__loading-text">Processing Payment</h3>
							</div>
						</div>
					}
				</div>
			) : (
				<div className="cart">
					<Heading heading='Your Art Cart is Empty' />

					<div className="wrapper">
						<h3 className="cart__empty">Add items to your Art Cart to get shopping!</h3>
					</div>
				</div>
			) }
		</>
	)
}

export default CheckoutWrapper;