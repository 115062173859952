import React, { useState, useEffect, useRef } from 'react';

const CheckoutDetails = props => {

	const { setDeviveryDetails } = props

	const form = useRef();

	const [ firstName, setFirstName ] = useState( '' )
	const [ lastName, setLastName ] = useState( '' )
	const [ addressLine1, setAddressLine1 ] = useState( '' )
	const [ addressLine2, setAddressLine2 ] = useState( '' )
	const [ city, setCity ] = useState( '' )
	const [ postcode, setPostcode ] = useState( '' )
	const [ county, setCounty ] = useState( '' )
	const country = "United Kingdom"

	useEffect(()=>{
		setDeviveryDetails({
			shippingName: firstName + " " + lastName,
			shippingFirstName: firstName,
			shippingLastName: lastName,
			shippingAddressLine1: addressLine1,
			shippingAddressLine2: addressLine2, 
			shippingCity: city,
			shippingPostcode: postcode,
			shippingCounty: county,
			shippingCountry: country
		})
	}, [ firstName, lastName, addressLine1, addressLine2, city, postcode, county, setDeviveryDetails ] )

	const handleFormSubmit = ( event ) => {
		event.preventDefault()
		form.current.reportValidity()
	}

	return (
		<div className="checkout-address">
			<form className="form" ref={ form }>
				<div className="form__input-container">
					<label className="form__label"  htmlFor="firstName">First Name</label>
					<input
						className="form__input form__input--pink"
						type="text" name="firstName" id="firstName" value={ firstName } placeholder="First name"
						onChange={ e => { setFirstName( e.target.value ) } }
						onBlur={ e => { handleFormSubmit( e ) }}
						required />
				</div>
				<div className="form__input-container">
					<label className="form__label"  htmlFor="lastName">Last Name</label>
					<input
						className="form__input form__input--yellow"
						type="text" name="lastName" id="lastName" value={ lastName } placeholder="Last name"
						onChange={ e => { setLastName( e.target.value ) } }
						onBlur={ e => { handleFormSubmit( e ) }}
						required />
				</div>

				<div className="form__input-container">
					<label className="form__label"  htmlFor="addressLine1">House No/Name</label>
					<input
						className="form__input form__input--green"
						type="text" name="addressLine1" id="addressLine1" value={ addressLine1 } placeholder="House number/name"
						onChange={ e => { setAddressLine1( e.target.value ) } }
						onBlur={ e => { handleFormSubmit( e ) }}
						required />
				</div>
				<div className="form__input-container">
					<label className="form__label"  htmlFor="addressLine2">Address 2</label>
					<input
						className="form__input form__input--blue"
						type="text" name="addressLine2" id="addressLine2" value={ addressLine2 } placeholder="Address Line 2"
						onChange={ e => { setAddressLine2( e.target.value ) } } />
				</div>
				<div className="form__input-container">
					<label className="form__label"  htmlFor="city">City</label>
					<input
						className="form__input form__input--pink"
						type="text" name="city" id="city" value={ city } placeholder="City"
						onChange={ e => { setCity( e.target.value ) } }
						onBlur={ e => { handleFormSubmit( e ) }}
						required />
				</div>
				<div className="form__input-container">
					<label className="form__label"  htmlFor="postcode">Postcode</label>
					<input
						className="form__input form__input--yellow"
						type="text" name="postcode" id="postcode" value={ postcode } placeholder="Postcode"
						onChange={ e => { setPostcode( e.target.value ) } }
						onBlur={ e => { handleFormSubmit( e ) }}
						required />
				</div>

				<div className="form__input-container">
					<label className="form__label"  htmlFor="county">County</label>
					<input
						className="form__input form__input--green"
						type="text" name="county" id="county" value={ county } placeholder="County"
						onChange={ e => { setCounty( e.target.value ) } }
						onBlur={ e => { handleFormSubmit( e ) }}
						required />
				</div>
				<div className="form__input-container">
					<div className="form__input form__input--blue">{ country }</div>
				</div>
			</form>
		</div>
	)
}

export default CheckoutDetails;