import React, { useEffect, useState, useContext } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import axios from 'axios'

import { navigate } from "gatsby"

import {
	useStripe,
	useElements,
	CardElement } from '@stripe/react-stripe-js';

import Loader from '../../images/svgs/loader.svg'

import { CartContext } from '../../context/CartContext'
import { GTPurchase } from '../../helpers/gtagEvents'
import { cartTotal } from '../../helpers/cart'

const Card_Styles = {
	style: {
		base: {
			color: "#000",
			fontFamily: "'Source Sans Pro Light', sans-serif",
			"::placeholder": {
				color: "#909090"
			},
			fontSize: "16px",
		},
		invalid: {
			color: "#e92349"
		}
	},
	hidePostalCode: true
}

export default function StripePayment( props ) {
	const stripe = useStripe();
	const elements = useElements();
	const { billingDetails, deliveryDetails, emailDetails, setProcessing, setProductError, setInternalError, totalPrice, subscribeNewsletter, subscriberFirstName, subscriberLastName } = props;

	const { cart, clearCart, postage, offers } = useContext( CartContext );

	const [ token, setToken ] = useState( null );
	const [ error, setError ] = useState( '' )

	const [ loading, setLoading ] = useState( true );

	const { shippingName, shippingAddressLine1, shippingAddressLine2, shippingCity, shippingCounty, shippingCountry, shippingPostcode } = deliveryDetails
	const valid = () => {
		// add billing here
		if( !shippingName || !shippingAddressLine1 || !shippingCounty || !shippingCountry || !shippingPostcode ) {
			return false
		}
		
		return true
	}

	const handleSubmit = async ( event ) => {
		setProcessing( true );
		event.preventDefault();

		const result = await stripe.confirmCardPayment( token, {
			payment_method: {
				card: elements.getElement( CardElement ),
				billing_details: {
					name: shippingName,
					email: emailDetails.contactEmail,
					address: {
						city: shippingCity,
						line1: shippingAddressLine1,
						line2: shippingAddressLine2,
						postal_code: shippingPostcode,
						state: shippingCounty
					}
				}
			}
		} )

		if (result.error) {
			// Show error to your customer (e.g., insufficient funds)
			setError(result.error.message)
			setProcessing( false );
			console.log('result.error.message', result.error.message);
		} else {
			// The payment has been processed!
			if ( result.paymentIntent.status === 'succeeded' ) {
				const data = {
					...deliveryDetails,
					...billingDetails,
					...emailDetails,
					paymentIntent: result.paymentIntent,
					cart,
					postageId: postage.id,
				}

				let payload; 
				
				payload = await axios.post( '/api/order/create/stripe', data );
				
				payload = payload.data

				if ( payload.status === 500 ) {
					setInternalError( { message: payload.message, link: true } )
					setProcessing( false )
					window.scrollTo( 0, 0 )
					return
				}
				navigate( '/checkout/success' )

				const gtData = {
					transactionId: result.paymentIntent.id,
					value: parseFloat( cartTotal( cart, postage, offers ) ),
					postagePrice: postage.price ? parseFloat( postage.price ) : 0
				}

				GTPurchase( cart, gtData )
				clearCart();
				setProcessing( false );
			}
		}

		if ( subscribeNewsletter ) {
			addToMailchimp( emailDetails.contactEmail, { FNAME: subscriberFirstName, LNAME: subscriberLastName } )
				.then( data => {
					// Don't need to do anything
				} )
				.catch( () => {
					// Fail silently
				} )
		}
	}
	
	useEffect( () => {

		const loadToken = async () => {
			setLoading( true );

			const data = {
				cart,
				postageId: postage.id,
				totalPrice
			}

			let payload = await axios.post( '/api/orders/payment/stripe', data )
			payload = payload.data
			switch ( payload.status ) {
				case 402:
					setProductError( payload.product )
					return;
				case 418:
					const errorString = payload.message
					setInternalError( { message: errorString, link: false } )
					window.scrollTo( 0, 0 )
					return;
				case 401:
					const errorString1 = "There seems to be a problem finding your details, please can you log out and try again."
					setInternalError( { message: errorString1, link: false } )
					window.scrollTo( 0, 0 )
					return;
				case 500: 
					const error500 = "There seems to be a problem setting up your order, please can you remove the items from your bag and try again"
					setInternalError( { message: error500, link: false } )
					window.scrollTo( 0, 0 )
					return;
				default:
					break;
			}

			setToken( payload.client_secret );
			setLoading( false );
		}

		if ( postage.id || postage.free ) {
			loadToken();
		}
		
	}, [ cart, postage, setProductError, setInternalError, totalPrice ] )

	return (
		<div className="stripe">
			{ loading ? (
				<div className="checkout__payment-loader-container">
					<span className="checkout__payment-loader">Loading...</span>
					<Loader className="loader" />
				</div>
			) : (
				<form className="form" onSubmit={ handleSubmit }>

					<div className="form__input-container">
						<span className="form__label">
							Card Details
						</span>
						<CardElement
							className="form__input form__input--blue stripe__element"
							options={Card_Styles}
							hidePostalCode={true} />
					</div>
					{ ( error.length > 1 ) && (
						<div className="form__error-container">
							<span className="form__error-message"> { error } </span>
							<span className="form__error-message">Please check your details are correct or try another payment method.</span>
						</div>
					)}

					<button className="form__submit form__submit--large" type="submit" disabled={ !stripe || !valid() }>
						Buy
					</button>
				</form>
			) }
		</div>
	)
}